<template>
  <div class="account-status">
    <div class="flex flex-row align-center">
      <div class="flex flex-column align-content-start">
        <span class="text-h6 font-weight-regular lighten-2 mb-1 px-0 text-dark">{{ $t('account.account_status') }}</span>
        <span class="text-subtitle-1 text-dark">{{ $t('account.sick_leave_title') }}</span>
      </div>
      <v-btn color="primary" outlined class="mb-0" @click="openSickLeaveManagement">
        {{ $t('account.sick_leave_cta') }}
      </v-btn>
    </div>
    <div class="mb-2">
      <v-data-table
        :headers="leavesTableConfig.headers"
        :items="formattedLeaves"
        :items-per-page="5"
        class="elevation-0"
        :footer-props="{
          'items-per-page-text': $t('sick_leave_management.items_per_page')
        }"
        :loading="loadingAccountStatus"
        :loading-text="$t('sick_leave_management.loading_results')">
        <template v-slot:no-results>
          <div class="flex flex-row justify-center pt-6 pb-2">{{ $t('sick_leave_management.no_results') }}</div>
        </template>
        <template v-slot:no-data>
          <div class="flex flex-row justify-center">{{ $t('sick_leave_management.no_results') }}</div>
        </template>
      </v-data-table>
    </div>
    <SickLeaveManagementModal v-model="showSickLeaveManagementModal" :professional-id="professionalId" @saved="retrieve"/>
  </div>
</template>

<script>

import SickLeaveManagementModal from "@/SickLeaveManagement/components/SickLeaveManagementModal.vue";
import {
  REASONS_FINAL,
  REASONS_TEMP,
  retrieveSickLeaveConfig
} from "@/SickLeaveManagement/services/sick-leave-management";
import dayjs from "dayjs";

export default {
  name: 'AccountStatus',
  components: {SickLeaveManagementModal},
  props: {
    professionalId: {
      type: Number,
      required: true,
    }
  },
  data: function () {
    return {
      showSickLeaveManagementModal: false,
      leavesTableConfig: {
        headers: [
          {
            text: this.$t('sick_leave_management.reason'),
            align: 'start',
            sortable: false,
            value: 'reason',
          },
          { text: this.$t('sick_leave_management.type'), value: 'type' },
          { text: this.$t('sick_leave_management.duration'), value: 'duration' },
        ]
      },
      leaves: null,
      loadingAccountStatus: true,
    };
  },
  computed: {
    formattedLeaves() {
      if (!this.leaves) {
        return [];
      }
      return this.leaves.map((leave) => ({
        reason: this.sickReason(leave),
        type: this.sickTypeName(leave),
        duration: leave.type === 2 ? `${this.sickStartAtFormatted(leave)} - ${this.sickEndAtFormatted(leave)}` : "",
      }))
    }
  },
  created() {
    this.retrieve();
  },
  methods: {
    sickTypeName(leave) {
      return leave && leave.type === 0 ? this.$t('sick_leave_management.final_sick_leave') : this.$t('sick_leave_management.temp_sick_leave');
    },
    sickReason(leave) {
      if (!leave) {
        return "";
      }

      const reasonsIds = [...Object.values(REASONS_TEMP).map((reason) => reason.type), Object.values(REASONS_FINAL).map((reason) => reason.type)];

      if (!reasonsIds.includes(leave.reason)) {
        return leave.reason;
      }

      if (leave.type === 0 && REASONS_FINAL.some((reason) => (reason.type === leave.reason))) {
        return this.$t(`sick_leave_management.${leave.reason}`);
      } else if (leave.type === 2 && REASONS_TEMP.some((reason) => (reason.type === leave.reason))) {
        return this.$t(`sick_leave_management.${leave.reason}`);
      } else if(leave.type === 0 || leave.type === 1) {
        return this.$t('sick_leave_management.other')
      }
    },
    sickStartAtFormatted(leave) {
      return leave && leave.start_at && dayjs(leave.start_at).format('DD/MM/YYYY');
    },
    sickEndAtFormatted(leave) {
      return leave && leave.end_at && dayjs(leave.end_at).format('DD/MM/YYYY');
    },
    retrieve() {
      retrieveSickLeaveConfig(this.professionalId)
        .then((data) => {
          this.leaves = data;
        })
        .catch((err) => {

        })
        .finally(() => {
          this.loadingAccountStatus = false;
        });
    },
    openSickLeaveManagement() {
      this.showSickLeaveManagementModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.text-dark {
  color: rgba(0, 0, 0, 0.87);
}
.flex {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.account-status {
  &__flex {
    display: block;

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__button {
    margin-top: rem(16);
    margin-bottom: rem(16);

    @include breakpoint(md) {
      margin-top: 0;
    }
  }

  &__hint {
    width: auto;

    @include breakpoint(md) {
      width: 50%;
    }
  }

  &__group-title {
    display: block;

    @include breakpoint(md) {
      display: inline;
    }
  }
}

.sick-leave {
  //&__title {
  //  @include breakpoint(md) {
  //    gap: 1rem;
  //  }
  //  gap: 0.5rem;
  //  align-items: center;
  //  flex-wrap: wrap;
  //}

  &__dates {
    color: #AAAAAA;
    font-size: 12px;
  }
}
</style>
