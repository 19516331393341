<template>
  <div class="account-languages">
    <div class="text-h6 font-weight-regular lighten-2 mb-1 px-0 text-dark">
      {{ $t('account_languages.receptionist_title') }}
    </div>
    <div class="px-0 py-0">
      <div class="languages-selector">
        <p class="languages-selector__label">{{ $t('account_languages.select_languages') }}</p>
        <v-autocomplete
          v-model="selectedLanguages"
          :items="languages"
          :loading="isLoadingLanguages"
          :disabled="selectedLanguages === null"
          chips
          small-chips
          deletable-chips
          multiple
          outlined
          hide-selected
          clearable
          :placeholder="$t('account_languages.search_languages')"
          item-text="name"
          item-value="id"
          @click:clear="reset"
        >
          <template v-slot:item="{ item }">
            <div class="py-1">
              <span class="languages-selector__item">
                {{ $t(`common.${item.name}`) }}
              </span>
            </div>
          </template>

          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              color="primary"
              @click="data.select"
              @click:close="removeSelectedLanguage(data.item.id)"
            >
              {{ $t(`common.${data.item.name}`) }}
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <div class="mb-2 text-right">
        <v-btn color="primary" :loading="isSaving" :disabled="isSaving" @click.native="save()">{{
            $t('common.save')
          }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import {retrieveLanguages, saveLanguages} from "@/AccountLanguages/services";

export default {
  name: 'AccountLanguages',
  props: {
    professionalId: {
      type: Number,
      required: true,
    }
  },
  data: function () {
    return {
      languages: [
        { id:'es',name: "spanish" },
        { id:'en',name: "english" },
        { id:'fr',name: "french"},
        { id:'pt',name: "portuguese"},
        { id:'de',name: "german"}
      ],
      selectedLanguages: null,
      isLoadingLanguages: false,
      isSaving: false
    };
  },
  created() {
    this.retrieve();
  },
  methods: {
    retrieve() {
      this.isLoadingLanguages = true;
      retrieveLanguages(this.professionalId)
        .then((data) => {
          this.selectedLanguages = data;
        })
        .catch((err) => {
          this.$toast.error(this.$t('errors.try_again'));
        })
        .finally(() => {
          this.isLoadingLanguages = false;
        });
    },
    async save() {
      try {
        this.isSaving = true;
        await saveLanguages(this.professionalId, this.selectedLanguages)
        this.$toast.success(this.$t('success.edit_user'));
      } catch (e) {
        this.$toast.error(this.$t('errors.try_again'));
      } finally {
        this.isSaving = false;
      }
    },
    reset() {
      this.selectedLanguages = null;
    },
    removeSelectedLanguage(lang) {
      const index = this.selectedLanguages.indexOf(lang);
      if (index > -1) {
        this.selectedLanguages.splice(index, 1);
      }
    }
  }
};
</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;
$highlightColor: #daf2f8;

.text-dark {
  color: rgba(0, 0, 0, 0.87);
}

.account-languages {
  &__flex {
    display: block;

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__button {
    margin-top: rem(16);
    margin-bottom: rem(16);

    @include breakpoint(md) {
      margin-top: 0;
    }
  }

  &__hint {
    width: auto;

    @include breakpoint(md) {
      width: 50%;
    }
  }

  &__group-title {
    display: block;

    @include breakpoint(md) {
      display: inline;
    }
  }
}

.languages-selector {
  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__label {
    font-size: 16px;
    color: $black;
    margin-bottom: 0.25rem !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__item {
    font-size: rem(16);
    color: $black;
    font-weight: 500;
  }

  & .v-select__selections input {
    max-height: inherit !important;
  }
}

.languages-selector {
  & .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    border-color: $grey;
    border-width: 0.125rem;
  }
}

</style>
