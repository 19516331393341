<template>
  <v-card flat class="md-card-profile">
    <v-card-text>
      <form class="mt-4">
        <v-layout wrap>
          <v-flex v-if="!isReceptionist" xs12 sm6 lg6>
            <label class="label_estado">{{ $t('user.professional_status') }}:</label>
            <v-radio-group v-model="user.estado" class="radio_estado" row @change="editData">
              <v-radio value="0" :label="$t('common.inactive')" color="grey" />
              <v-radio value="1" :label="$t('common.active')" color="green" />
            </v-radio-group>
          </v-flex>
          <v-flex v-if="!isReceptionist" xs12 sm6 lg6 class="text-left text-sm-right">
            <label>{{ $t('user.verification_state') }}:</label><br class="hidden-xs-only" />
            <v-switch
              v-model="user.verificado"
              style="margin-top: -5px; float: right"
              :label="
                user.verificado === 0 || user.verificado === false ? $t('common.sin_verificar') : $t('common.verified')
              "
              color="primary"
              @change="editData"
            />
          </v-flex>
          <v-flex v-if="user.estado === '2' && !isReceptionist" xs12 sm4 lg4>
            <v-menu
              ref="fFinTrial"
              v-model="user.fechaFinTrialMenu"
              class="mr-2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              :return-value.sync="user.stop_trial"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="user.stop_trial"
                  :label="$t('user.date_end_trial')"
                  append-icon="mdi-calendar"
                  readonly
                  class="input_ffintrial"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="user.stop_trial"
                first-day-of-week="1"
                :locale="this.$i18n.locale"
                no-title
                scrollable
                @input="$refs.fFinTrial.save(user.stop_trial)"
                @change="editData"
              >
                <v-spacer />
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex v-if="!isReceptionist" xs6 sm4 lg4>
            <v-text-field
              v-model="user.ult_login"
              class="mr-2"
              :label="$t('user.last_login')"
              append-icon="mdi-account-circle"
              type="text"
              readonly
            />
          </v-flex>
          <v-flex v-if="!isReceptionist" xs6 sm4 lg4>
            <v-text-field
              v-model="user.ult_acceso"
              class="mr-2"
              :label="$t('user.last_connection')"
              readonly
              append-icon="mdi-view-dashboard"
              type="text"
            />
          </v-flex>
          <v-flex xs6 sm6 lg6>
            <v-text-field
              v-model="user.password"
              :rules="passwordRules"
              class="mr-2"
              :label="$t('message.password')"
              append-icon="mdi-lock"
              type="password"
              required
              @change="checkPassword"
            />
          </v-flex>
          <v-flex xs6 sm6 lg6>
            <v-text-field
              v-model="user.password_repeat"
              class="mr-2"
              :label="$t('user.password_repeat')"
              append-icon="mdi-lock"
              type="password"
              required
              @change="checkPassword"
            />
          </v-flex>
        </v-layout>
      </form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
    specialties: {
      type: Array,
      default: () => [],
    },
    isReceptionist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      passwordRules: [
        v => this.containsLowercase(v || '') || this.$t('user.password_must_contain_lowercase'),
        v => this.containsUppercase(v || '') || this.$t('user.password_must_contain_uppercase'),
        v => this.containsNumbers(v || '') || this.$t('user.password_must_contain_numbers'),
        v => this.containsSymbols(v || '') || this.$t('user.password_must_contain_symbols'),
        v => (v && v.length >= 8) || this.$t('user.password_min_length', { num: 8 }),
      ],
    };
  },

  methods: {
    editData() {
      this.$emit('editingData', true);
    },

    checkPassword() {
      if (this.user.password === '') {
        this.$emit('passwordValid', true);
        return true;
      }

      if (this.user.password_repeat === '' || typeof this.user.password_repeat === 'undefined') {
        this.$emit('passwordValid', false);
        return false;
      }

      if (this.user.password !== this.user.password_repeat) {
        this.$emit('passwordValid', false);
        this.$toast.error(this.$t('user.password_no_igual'));
        return false;
      }

      if (this.user.password_repeat.length < 8) {
        this.$emit('passwordValid', false);
        this.$toast.error(this.$t('user.password_min_length', { num: 8 }));
        return false;
      }

      if (
        !(
          this.containsLowercase(this.user.password) &&
          this.containsUppercase(this.user.password) &&
          this.containsNumbers(this.user.password) &&
          this.containsSymbols(this.user.password)
        )
      ) {
        this.$emit('passwordValid', false);
        this.$toast.error(this.$t('user.invalid_password'));
        return false;
      }

      this.$emit('passwordValid', true);
      this.$emit('editingData', true);
      return true;
    },

    containsLowercase(str) {
      return !!str.match(/[a-z]/);
    },
    containsUppercase(str) {
      return !!str.match(/[A-Z]/);
    },
    containsNumbers(str) {
      return !!str.match(/\d/);
    },
    containsSymbols(str) {
      return !!str.match(/\W/);
    },
  },
};
</script>
<style scoped lang="css">
.md-card-profile {
  width: 96%;
  margin: -25px auto 0;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.label_estado {
  margin-bottom: 0;
}
.radio_estado {
  margin-top: 3px;
}
@keyframes enfasis_btn {
  from {
    width: 140px;
    height: 40px;
    top: 135px;
    margin-left: 43px;
  }
  to {
    width: 150px;
    height: 50px;
    top: 128px;
    margin-left: 38px;
  }
}
</style>
