<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" width="100%" max-width="1100" class="transparent mb-5">
        <div id="professional">
          <v-toolbar flat class="transparent mb-4">
            <h1 class="headline black--text mr-3">{{ $t('user.edit_professional') }}</h1>
            <v-chip v-if="professional.company" label rounded small color="primary" class="mx-1 white--text">
              {{ professional.company.name }}
            </v-chip>
            <v-spacer />
            <v-btn
              rounded
              color="primary"
              class="ml-3"
              depressed
              :disabled="disabledButton"
              @click="saveProfessional()"
            >
              {{ $t('user.edit') }}
            </v-btn>
          </v-toolbar>
          <v-card>
            <professional-tabs @updateTab="updateTabHandle" />
            <v-divider />
            <v-tabs-items v-model="tab">
              <v-tab-item key="0">
                <professional-personal-data :professional="professional" @editingData="$emit('editingData', true)" />
              </v-tab-item>
              <v-tab-item key="1">
                <professional-aditional-info
                  :professional="professional"
                  :specialties="specialties"
                  :is-receptionist="true"
                  :loading="loading"
                  @editingData="$emit('editingData', true)"
                />
              </v-tab-item>
              <v-tab-item key="2">
                <professional-access
                  :user="user"
                  :is-receptionist="true"
                  @editingData="$emit('editingData', true)"
                  @passwordValid="passwordIsValidHandle"
                />
              </v-tab-item>
              <v-tab-item v-if="enabledScheduleView" key="3">
                <ProfessionalAvailability />
              </v-tab-item>
              <v-tab-item v-if="!enabledScheduleView" key="3">
                <professional-schedule
                  :weekdays="weekdays"
                  :check-day="checkDay"
                  :user="user"
                  :professional-id="professional.id"
                  :schedule-id="scheduleId"
                  @editingData="$emit('editingData', true)"
                  @scheduleValid="scheduleIsValidHandle"
                />
              </v-tab-item>
              <v-tab-item v-if="accountManagementEnabled" key="4">
                <ProfessionalAccountStatus
                  :professional-id="professionalId"
                />
              </v-tab-item>
              <v-tab-item :key="accountManagementEnabled ? 5 : 4">
                <ProfessionalServices
                  v-if="professionalSpecialties.length > 0"
                  v-model="ageGroupSpecialtiesRelation"
                  :user-id="user.docline_api_id"
                  :professional-specialties="professionalSpecialties"
                />
              </v-tab-item>
            </v-tabs-items>
            <div class="text-center pt-5 mb-5">
              <v-btn
                v-if="tab !== 4"
                :loading="isSaving"
                rounded
                class="mb-5"
                :disabled="disabledButton"
                depressed
                color="primary"
                @click="saveProfessional()"
              >
                {{ $t('user.edit') }}</v-btn
              >
            </div>
          </v-card>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getUsuarioUrl, getHeader } from '@/config/config';
import ProfessionalPersonalData from '@/components/professionals/ProfessionalPersonalData';
import ProfessionalTabs from '@/components/professionals/ProfessionalTabs';
import ProfessionalAditionalInfo from '@/components/professionals/ProfessionalAditionalInfo';
import ProfessionalAccess from '@/components/professionals/ProfessionalAccess';
import ProfessionalSchedule from '@/components/professionals/ProfessionalSchedule';
import { putProfessional } from '@/views/professionals/professionals.api';
import ProfessionalServices from "@/components/professionals/ProfessionalServices.vue";
import {setAgeGroupToProfessionalSpecialty} from "@/MinorsTreatment/AgeGroupSpecialties/services";
import ProfessionalAccountStatus from "@/components/professionals/ProfessionalAccountStatus.vue";
import ProfessionalAvailability from "@/components/professionals/ProfessionaAvailability.vue";

export default {
  components: {
    ProfessionalAvailability,
    ProfessionalAccountStatus,
    ProfessionalServices,
    ProfessionalAccess,
    ProfessionalAditionalInfo,
    ProfessionalTabs,
    ProfessionalPersonalData,
    ProfessionalSchedule,
  },
  props: {
    professionalId: {
      type: Number,
      default: 0,
    },
    professional: {
      type: Object,
      default: () => null,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      disabledButton: true,
      isSaving: false,
      tab: 0,
      weekdays: [],
      checkDay: [],
      user: {
        name: '',
        idioma_biografia: 'es',
      },
      specialties: [],
      hideCollegiateNumber: false,
      passwordIsValid: true,
      scheduleIsValid: true,
      scheduleId: null,
      ageGroupSpecialtiesRelation: null,
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),
    professionalSpecialties() {
      let professionalSpecialties = [];

      if (this.specialties.length && this.user.specialties.length) {
        professionalSpecialties = this.specialties.filter((specialty) => {
          return this.user.specialties.includes(specialty.id);
        })
      }

      return professionalSpecialties;
    },
    enabledScheduleView() {
      return this.permissions.newSchedulePage;
    },
    sickLeaveManagementEnabled() {
      return this.permissions.sickLeaveManagement;
    },
    accountSpokenLanguages() {
      return this.permissions.accountSpokenLanguages;
    },
    accountManagementEnabled() {
      return this.sickLeaveManagementEnabled || this.accountSpokenLanguages;
    }
  },

  watch: {
    loading(val) {
      this.disabledButton = val;
    },
  },

  created() {
    const allowAccess = this.authUser !== null && (this.authUser.rol === 1 || this.authUser.rol === 3);
    if (allowAccess && this.professional.id !== '' && this.professional.id !== 'undefined') {
      this.$http
        .post(getUsuarioUrl, { user_id: this.authUser.id, usuario_id: this.professionalId }, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data && response.data.usuario) {
            this.user = response.data.usuario;
            if (typeof response.data.usuario.id === 'undefined') {
              this.$router.push('/professionals');
            }
            this.scheduleId = response.data.schedule_id;
            this.weekdays = response.data.dias;
            this.checkDay = response.data.checkdia;
            this.weekdays[0].name = this.$t('agenda.lunes');
            this.weekdays[1].name = this.$t('agenda.martes');
            this.weekdays[2].name = this.$t('agenda.miercoles');
            this.weekdays[3].name = this.$t('agenda.jueves');
            this.weekdays[4].name = this.$t('agenda.viernes');
            this.weekdays[5].name = this.$t('agenda.sabado');
            this.weekdays[6].name = this.$t('agenda.domingo');
            this.date = this.user.fechaNacimiento;
            this.specialties = response.data.especialidades;
            this.loading = false;
            this.disabledButton = false;
          }
        })
        .catch(err => {
          if (err.status === 401) {
            localStorage.removeItem('auth_ehealth');
            this.$router.push('Login');
          }
        });
    } else {
      this.$router.push('/dashboard');
    }
    this.checkHideCollegiateNumber();
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    passwordIsValidHandle(value) {
      this.passwordIsValid = value;
    },

    scheduleIsValidHandle(value) {
      this.scheduleIsValid = value;
    },

    validateForm() {
      let mustShowAlertMessage = '';
      const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (this.professional.name === null || this.professional.name.length < 3) {
        mustShowAlertMessage = this.$t('patient.full_name_required');
      } else if (this.professional.idNumber === null || this.professional.idNumber.length < 1) {
        mustShowAlertMessage = this.$t('patient.dni_required');
      }
      if (this.professional.email === '') {
        mustShowAlertMessage = this.$t('errors.email_required');
      } else if (!regex.test(this.professional.email)) {
        mustShowAlertMessage = this.$t('errors.email_invalid');
      } else if (
        typeof this.professional.email_repeat !== 'undefined' &&
        this.professional.email_repeat !== '' &&
        this.professional.email_repeat !== this.professional.email
      ) {
        mustShowAlertMessage = this.$t('errors.email_repeat');
      } else if (!this.passwordIsValid) {
        mustShowAlertMessage = this.$t('user.invalid_password');
      } else if (!this.scheduleIsValid) {
        mustShowAlertMessage = this.$t('errors.schedule_not_valid');
      }

      if (mustShowAlertMessage !== '') {
        this.$toast.error(mustShowAlertMessage);
      }
      return mustShowAlertMessage === '';
    },

    async saveProfessional() {
      if (this.validateForm()) {
        this.disabledButton = true;
        const resultEditProfessional = await putProfessional(this.professional.id, this.professional);
        if (resultEditProfessional) {
          const resultEditUser = await putProfessional(this.professional.id + '/user', {
            user: this.user,
            weekdays: this.weekdays,
          });
          if (resultEditUser) {
            this.$toast.success(this.$t('success.edit_user'));
          }
        }
        if (this.ageGroupSpecialtiesRelation) {
          await setAgeGroupToProfessionalSpecialty(this.user.docline_api_id, this.ageGroupSpecialtiesRelation);
        }
        this.loading = false;
        this.$emit('editingData', false);
        this.disabledButton = false;
      }
    },

    updateTabHandle(value) {
      this.tab = value;
    },

    checkHideCollegiateNumber() {
      const user = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      if (
        typeof user !== 'undefined' &&
        user.nombreEmpresa !== '' &&
        typeof user.nombreEmpresa !== 'undefined' &&
        user.nombreEmpresa.toUpperCase() === 'COFENAT'
      ) {
        this.hideCollegiateNumber = true;
      }
    },
  },
};
</script>
<style scoped lang="css">
@keyframes enfasis_btn {
  from {
    width: 40px;
    height: 40px;
    top: 135px;
    margin-left: 83px;
  }
  to {
    width: 50px;
    height: 50px;
    top: 128px;
    margin-left: 78px;
  }
}
</style>
