<template>
  <v-card flat class="md-card-profile">
    <v-card-text>
      <div class="mt-4">
        <AccountLanguages v-if="accountSpokenLanguages" :professional-id="professionalId" />
        <v-divider v-if="sickLeaveManagementEnabled && accountSpokenLanguages" class="mt-4"/>
        <div v-if="sickLeaveManagementEnabled" class="mt-4">
          <AccountStatus :professional-id="professionalId" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AccountStatus from "@/components/account/layout/AccountStatus.vue";
import AccountLanguages from "@/components/account/layout/AccountLanguages.vue";
import {mapState} from "vuex";

export default {
  name: "ProfessionalAccountStatus",
  components: {AccountLanguages, AccountStatus},
  props: {
    professionalId: {
      type: Number,
      required: true,
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),
    sickLeaveManagementEnabled() {
      return this.permissions.sickLeaveManagement;
    },
    accountSpokenLanguages() {
      return this.permissions.accountSpokenLanguages;
    },
  }
};
</script>
